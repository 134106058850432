<template>
  <WeContainer card="" v-if="ready">
    <Navigation
      v-on:save-form="saveForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
    />
    <Content ref="content" />
    <WeSeoBar
      v-bind:focus-keyword="packages.info.name"
      v-bind:metaTitle="packages.detail.meta.title"
      v-bind:metaDescription="packages.detail.meta.description"
      v-bind:description="packages.detail.descriptions"
      v-bind:images="packages.info.image.files"
      v-bind:slug="packages.info.slug"
    />
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
const Navigation = () => import("./Navigation/Index");
const Content = () => import("./Content/Index");

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: false,
      errors: [],
    };
  },
  components: {
    Navigation,
    Content,
  },
  methods: {
    ...mapActions("packages", ["create", "update", "load", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.errors = [];

      let informationValid = this.$refs.content.$refs.information.$v;
      informationValid.$touch();

      if (informationValid.$invalid) {
        this.errors.push("info");
      }

      return this.formAction();
    },
    formAction() {
      if (this.errors && this.errors.length) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    getPackageById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    addPackage() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Ürün Paket listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updatePackage() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Ürün paketleri listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/packages");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    saveForm() {
      if (this.$route.params.id) {
        this.updatePackage();
      } else {
        this.addPackage();
      }
    },
  },
  computed: {
    ...mapState(["shared", "packages"]),
  },
  mounted() {
    this.load();
    this.getPackageById();
  },
};
</script>
